var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"vid":"name","name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Your Full Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.name),expression:"payload.name"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","id":"name","aria-describedby":"emailHelp","placeholder":"Your Full Name"},domProps:{"value":(_vm.payload.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.email),expression:"payload.email"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"email","id":"email","aria-describedby":"emailHelp","placeholder":"Enter email"},domProps:{"value":(_vm.payload.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.password),expression:"payload.password"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"password","id":"password","placeholder":"Password"},domProps:{"value":(_vm.payload.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"d-inline-block w-100"},[_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit"}},[_vm._v("Sign Up")])]),_c('div',{staticClass:"sign-info"},[_c('span',{staticClass:"dark-color d-inline-block line-height-2"},[_vm._v(" Already Have Account ? "),_c('router-link',{staticClass:"iq-waves-effect pr-4",attrs:{"to":"/auth/sign-in1"}},[_vm._v(" Sign in ")])],1),_c('social-login-form')],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }